<template>
  <div class="unit--lesson">
    <input :id="checkboxId" class="toggle" type="checkbox">
    <label :for="checkboxId" class="unit--lesson-title lbl-toggle">
      <span>{{ number }}</span> {{ title }}
    </label>
    <div class="collapsible-content">
      <div class="content-inner">
        <slot/>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    number: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    }
  },
  computed: {
    checkboxId () {
      return `collapsable-${this.number}`
    }
  }
}
</script>

<style scoped>
input[type='checkbox'] {
  display: none;
}

.unit--lesson-title:before {
  content: ' ';
  /* display: inline-block; */
  position:absolute;
  left:20px;
  top:20px;

  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;
  transition: transform .2s ease-out;
}

.collapsible-content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height .25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: 100vh;
}

.toggle:checked + .lbl-toggle::before {
  transform: rotate(90deg) translateX(-3px);
}

.unit--lesson-title {
  display:block;
  position:relative;
  text-decoration:none;
  line-height:1.2;
  padding:13px 0 11px 116px;
  cursor: pointer;
  transition: all 0.25s ease-out;
  border-bottom:1px solid rgba(255,255,255,0.1)
}

.unit--title span {
  display:block;
  position:absolute;
  left:0;
  float:left;
  width:60px
}

.unit--lesson-title span:first-child {
  position:absolute;
  left:60px
}
</style>