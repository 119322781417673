<template>
  <div class="form-card">
    <ValidationObserver tag="form"  v-slot="{ invalid }">

      <form @submit.prevent="submitForm">
        <Select 
          label="Datum"
          placeholder="Selecteer een datum"
          :options="fmtAppointments"
          v-model="form.appointment"
          rules="required"
        />

        <Input 
          required 
          label="Naam"
          rules="required|person"
          v-model="form.name"
        />

        <Input 
          required 
          label="Email"
          rules="required|email|emailunique"
          v-model="form.email"
        />

        <Input 
          required 
          label="Telefoonnumer"
          rules="required|mobile"
          v-model="form.telephone"
        />

        <Checkbox
          v-model="form.terms"
          rules="boolean"
          text="Ik ben ouder dan 24 en ik ga akkoord met de voorwaarden"
        /> 

        <BaseButton :disabled="invalid" type="submit">
          VROEM
        </BaseButton>

      </form>
    </ValidationObserver>   
  </div>
</template>

<script>
import Input from "@/components/Form/Input.vue";
import Select from "@/components/Form/Select.vue";
import Checkbox from "@/components/Form/Checkbox.vue";

import { capitalizeFirstLetter } from "@/utils/strings";

import ersPub from "@/api/ersPub.js"
import ersMob from "@/api/ersMob.js"

export default {
  components: {
    Input,
    Select,
    Checkbox,
  },
  async created () {
    let result = await ersPub.getAppts() 
    this.appointments = result; 
  },
  computed: {
    fmtAppointments () {
      return this.appointments.map(appointment => {
        let id = appointment.idAppointment
        let start = new Date(appointment.startTime * 1000)
        let end = new Date(appointment.endTime * 1000)
        let date = start.toLocaleDateString('nl-NL', { weekday: 'long', month: 'long', day: 'numeric' })
        let startTime = start.toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit' })
        let endTime = end.toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit' })
        let value = capitalizeFirstLetter(date) + ', ' + startTime + ' - ' + endTime
        return { id, value  } 
      })
    }
  },
  data () {
    return {
      valid: false,
      appointments: [],
      form: {
        appointment: '',
        name: '', 
        email: '',
        telephone: '',
        terms: false
      }
    }
  },
  methods: {
    async submitForm() {
      let credentials = await ersPub.registerNewStudent(this.form) 
      await ersMob.login(credentials)
      this.$router.push({ path: 'betalen' })
    }
  }
}
</script>


<style scoped>
form {
  width: 75%;  
  margin: auto;
}
/* 
label, input, .floating-label{
  display: block;
  margin-bottom: 30px;
} */

.form-card {
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 20px;

  max-width:740px;
  padding-bottom:10px;
  background-color:#333;
  box-shadow:0 2px 14px rgba(0,0,0,0.2)
}


</style>