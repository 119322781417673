import Vue from "vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { extend } from 'vee-validate';
import { setInteractionMode } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import ersPub from "@/api/ersPub.js"

// Vue.use(VeeValidate, {
//   classes: true
// });

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

setInteractionMode('eager');

const regex = {
  person: /^(\S+\s)+\S+$/i, 
  mobile: /^(((\\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$/i,
}

extend('email', {
  ...email,
  message: "Een geldig emailadres is nodig "
});

extend('mobile', {
  validate: value => regex.mobile.test(value),
  message: 'Een geldig mobiel nummer is nodig'
});

extend('person', {
  validate: value => regex.person.test(value),
  message: 'Een voor- én achternaam is nodig'
})

extend('emailunique', {
  validate: async function (value) { return await ersPub.checkEmailUnique(value) },
  message: 'Dit emailadres is al geregistreerd'
})

extend('required', {
  ...required,
  message: 'Dit veld mag niet leeg zijn'
});

extend('boolean', {
  validate: (value) => Boolean(value),
  message: 'Deze moet aangezet worden'
})