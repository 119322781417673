<template>
  <main>
    <section id="rijschool" aria-label="Betaling afronden" class="section--medium">
      <div class="section--container">
        <form @submit.prevent="submitForm">

          <h2>Bijna klaar...</h2>

          <div class="payment-wrapper"> 

            <p>Leuk dat je bij ons wil komen proeflessen! Het enige dat je nog even moet doen is de proefles betalen. Mocht je uitendelijk bij ons komen lessen en een pakket aanschaffen, <strong>dan zullen we de proefles vergoeden</strong>.</p>

            <p>We hebben ook alvast een account voor je aangemaakt voor <i>e-rijschool.nl</i>, dit is ons agendasysteem. Het wachtwoord hiervoor vindt je in je email.</p>


            <TransactionList :transaction="transaction" />
            
            <iDealSelector
              :issuers="issuers"
              v-model="issuerId" 
            />

            <BaseButton :disabled="!issuerId" type="submit">
              BETALEN
            </BaseButton>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import iDealSelector from "@/components/Checkout/iDealSelector.vue";
import TransactionList from "@/components/Checkout/TransactionList.vue";
// import ersPub from "@/api/ersPub.js"
import ersMob from "@/api/ersMob.js"

export default {
  components: {
    TransactionList,
    iDealSelector
  },
  async created () {
    let { issuers, transactions, basketTotal } = await ersMob.getOpenTransactions()
    this.issuers = issuers.issuers;
    this.transactions = transactions
    this.total = basketTotal
  },
  data () { 
    return {
      result: {},
      issuers: [],
      issuerId: null, 
      transactions: [],
      total: null,
    }
  },
  computed: {
    transaction() {
      if (this.transactions.length > 0) {
        return this.transactions[0]
      } else {
        return {}
      }
    }
  },
  methods: {
    async submitForm() {
      let transactions = this.transactions.map(t => t.idTransaction);
      let issuerId = this.issuerId;
      if (typeof issuerId != "string") {
        console.error("No valid issuer selected")
        return 
      }
      let result = await ersMob.startIdealPayment({ transactions, issuerId });
      if (!result) {
        console.error("No resolt from payment initiation")
        return 
      }
      window.location.replace(result);
    }
  }
}
</script>


<style scoped>
.payment-wrapper {
  border-radius: 5px;
  background-color: #fff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  padding-bottom: 50px;
}
</style>