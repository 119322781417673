<template>
  <div class="component--checkbox">
    <ValidationProvider 
      :rules="rules" 
      v-slot="{ classes, ariaInput }"
    >
      <input 
        id="permitted" 
        type="checkbox" 
        :class="classes"
        v-model="cValue"
        v-bind="ariaInput"
      >
      <label for="permitted" class="side-label">{{ text }}</label>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: '',
    }, 
  },
  computed: {
    cValue: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', Boolean(newValue))
      }
    }
  },
}
</script>


<style scoped>

input[type="checkbox"]  {
  position: absolute;
  left: -9999px;
}

label {
  font-size: 0.9em;
  color: white;
  opacity: 0.8;
  font-weight: 400;
}

.side-label {
  display: block;
  position: relative;
  margin: 10px 0;
  padding-left: 35px;
  cursor: pointer;
}

.side-label::before, .side-label::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
}

.side-label::before {
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;  
}

input:focus + .side-label::before {
  border-color: #9ab593;
}

.side-label::after {
  display: none;
  width: 12px;
  height: 12px;
  margin: 6px;
  background-color: #A86B15;
}

input:checked + .side-label::after {
  display: block;
}
</style>