<template>
  <main>
    <section aria-label="Onze rijschool" class="section--medium">
      <div class="section--container">

          <h2>Proefles mislukt!</h2>

          <div class="failure-wrapper"> 
            <p>Helaas, het boeken van de proefles is niet helemaal goed gegaan. Omdat je nu wel een account hebt op e-rijschool, kun je het best inloggen met de inloggegevens die je per e-mail hebt ontvangen. Kom je er niet uit? Bel of app dan even met Marcel via 06 54770770.</p>
          </div>
      </div>
    </section>
  </main>
</template>

<script>
import ersMob from "@/api/ersMob.js"

export default {
  async created () {
    let { issuers, transactions, basketTotal } = await ersMob.getOpenTransactions()
    this.issuers = issuers.issuers;
    this.transactions = transactions
    this.total = basketTotal
  },
}
</script>


<style scoped>
.failure-wrapper {
  border-radius: 5px;
  background-color: #fff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  padding-bottom: 50px;
}
</style>