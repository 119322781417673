<template>
  <div class="wrapper">
    <div 
      v-for="{ issuerId, issuerName} in issuers"
      :key="issuerName"
      @click.stop="selectIssuer(issuerId)"
      :class="{ selected: selected == issuerId }"
      class="grid-box"
    >
      <div class="c-media  c-media--center">
        <figure data-testid="tile-figure" class="c-media__figure">
          <img :src="getLogo(issuerName)" :alt="issuerName">
        </figure>
        <div class="c-media__body">{{ issuerName }}</div>
      </div>
    </div>
  </div>
</template>


<script>
// import Moneyou from "/public/img/moneyou.svg";

export default {
  props: {
    issuers: {
      default: () => []
    }
  },
  data () {
    return {
      selected: null,
      banks: {
        "ABN AMRO": "/img/sns.svg", 
        "ASN Bank": "/img/asn.svg", 
        "bunq": "/img/bunq.svg", 
        "Handelsbanken": "/img/handelsbanken.svg", 
        "ING": "/img/ing.svg", 
        "Knab": "/img/knab.svg", 
        "Moneyou": "/img/moneyou.svg", 
        "Rabobank": "/img/rabobank.svg", 
        "RegioBank": "/img/regiobank.svg", 
        "Revolut": "/img/revolut.svg", 
        "Triodos Bank": "/img/triodos.svg", 
        "Van Lanschot": "/img/vanlanschot.svg", 
        "SNS": "/img/sns.svg"
      }
    }
  },
  methods: {
    selectIssuer (issuerId) {
      this.selected = issuerId;
      this.$emit('input', issuerId)
    },
    getLogo (name) {
      let image = this.banks[name];
      if (typeof image === "undefined") return "/img/unknown.svg"
      return image
    }
  }
}
</script>


<style scoped>

.svg-inline--bi {
	display: inline-block;
	overflow: visible;
	width: 2em;
	height: 2em;
	font-size: inherit;
	/* vertical-align: -.125em; */
  vertical-align: middle; 
	fill: currentColor;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr) ) ;
  /* grid-gap: 5px; */
}

figure {
  display: flex;
  justify-content: center;
  align-content: center;
}

.grid-box {
  border-radius: 5px;
  padding: 10px;
  font-size: 1rem; 
}

.selected {
  background-color: lightgrey;
}

.c-media__body { 
  display: flex;
  align-content: center;
  justify-content: center;
}
</style>