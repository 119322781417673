<template>
  <div class="transactions-wrapper">
    <div class="appointment">
      <h4>
        Proefles op {{ date }} om {{ time }}, duurt {{ durationInHours}} uur voor € {{ price }}
      </h4>
    </div> 
  </div>
</template>


<script>
export default {
  props: {
    transaction: {
      type: Object,
      default: () => {}
    }
  },
  created () {
    // this.``
  },
  computed: {
    price () {
      if ('price' in this.transaction) {
        return this.transaction.price.replace('.', ',')
      } 
      return ''
    },
    date () {
      if ('description' in this.transaction) {
        let splitted = this.transaction.description.split(' ')
        return splitted[3]
      }
      return ''
    }, 
    time () {
      if ('description' in this.transaction) {
        let splitted = this.transaction.description.split(' ')
        return splitted[4]
      }
      return ''
    },
    durationInMinutes () {
      if ('description' in this.transaction) {
        let splitted = this.transaction.description.split(' ')
        return splitted[5].replace(/\D/g,'')
      }
      return ''
    },
    durationInHours () {
      if ('description' in this.transaction) {
        return this.durationInMinutes / 60 
      }
      return ''
    }
  }
}
</script>


<style scoped>
.wrapper {
  background-color: #fff;
  color: #444;
}

.appointment {
  display: flex;
  align-content: center;
  justify-content: center;
  border-width: 2px;
  border-style: solid;
  border-radius: 15px;
  border-color: grey;
  margin: 50px;
}

</style>