<template>
  <ValidationProvider 
    :rules="rules" 
    v-slot="{ errors, classes, ariaInput }"
  >
    <div class="floating-label">
      <select 
        :class="classes"
        :name="label.toLowerCase()" :id="label.toLowerCase()"
        :required="required"
        v-model="cValue"
        v-bind="ariaInput"
      >
        <option value="" disabled selected>{{ placeholder }}</option>
        <option 
          v-for="option in options" 
          :key="option.id" 
          :value="option.id"
        >{{ option.value }}</option>
      </select>
      <label :for="label.toLowerCase()">{{ label }}</label>
    </div>
    <span class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>


<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
      default: ""
    },
    label: {
      type: String,
      default: null 
    },
    placeholder: {
      type: String,
      default: null 
    },
    options: {
      type: Array,
      default: () => [],
      validator: function (list) {
        return list.reduce((acc, cur) => {
          if (!('id' in cur) || !('value' in cur)) {
            return false 
          } 
          return acc
        }, true)

      },
    },
    required: {
      type: Boolean,
      default: true 
    },
    rules: {
      type: String,
      default: '',
    },  
  },
  computed: {
    cValue: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style scoped>
select {
  font-size: 16px;
  padding: 10px 10px;
  height: 46px;
  border: 2px solid #eee;
  border-radius: 4px;
  /* background: #eee; */
  box-sizing: border-box;
  transition: all 0.3s linear;
  color: #000;
  font-weight: 400;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 10px;
}

label {
  font-size: 12.5px;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
}

span .error {
  display: inline-block;
  height: 16px;
  font-size: 12px;
  color: grey;
}

/* select:valid {
  background: url(../../assets/img/icons/check.svg) no-repeat 95% 50% #eee;
  background-size: 25px;
}

select:invalid {
  background: url(../../assets/img/icons/clear.svg) no-repeat 95% 50% #eee;
  background-size: 25px;
  color: #666;
} */

.floating-label {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
}

.floating-label label {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
  padding-left: 12px;
}


.floating-label select:valid {
  padding: 16px 0px 0px 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


select:focus {
  border: solid 2px #A86B15;
  outline: 0;
  box-shadow: 0 2px 6px -8px rgba(182, 157, 230, .45);
}

.floating-label select {
  width: 100%;
  margin-left: auto;
  display: flex;
}

.floating-label select:valid + label {
  transform: translateY(-10px);
  opacity: 0.7;
}

.floating-label select:valid + label + .icon svg {
  opacity: 1;
}

.floating-label select:valid + label + .icon svg path {
  fill: #b69de6;
}

.floating-label select:not(:valid):not(:focus) + label + .icon {
  animation-name: shake-shake;
  animation-duration: 0.3s;
}

@keyframes shake-shake {
  0% {
    transform: translateX(-3px);
  }
  20% {
    transform: translateX(3px);
  }
  40% {
    transform: translateX(-3px);
  }
  60% {
    transform: translateX(3px);
  }
  80% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(0px);
  }
}

</style>