import axios from "axios";
var parser = new DOMParser();

class ersMob {
  constructor() {

    this.baseParams = {}

    this.axios = axios.create({
      baseURL: "https://www.motorrijles-amsterdam.nl/",
      timeout: 60000,
      withCredentials: true,
    });
  }

  getParams(p) {
    return { ...this.baseParams, ...p }
  }

  unpackStatus(xml) {
    let status = xml.getElementsByTagName('status')[0]
    let text = status.textContent
    let code =  status.attributes[0].textContent
    
    return { code, text }
  }

  unpackMessages(xml) {
    let m = xml.getElementsByTagName('messages')[0].children
    let messages = {}
    for (let item of m) {
      messages[item.nodeName] = item.innerHTML;
    }
    return messages
  }

  unpackContent(xml) {
    let json = xml.getElementsByTagName('content')[0].textContent
    try {
      return JSON.parse(json)
    } catch (e) {   // invalid JSON 
      return null 
    }
  }

  unpackCredentials(xml) {
    try {
      let username = xml.getElementsByTagName('userId')[0].textContent
      let password = xml.getElementsByTagName('userPassword')[0].textContent
      return { username, password }
    } catch (error) {
      return null
    }
  }

  unpackXML(data) {
    let xml = parser.parseFromString(data, "application/xml");

    let messages = this.unpackMessages(xml)
    let content = this.unpackContent(xml)
    let status = this.unpackStatus(xml)
    let credentials = this.unpackCredentials(xml)

    return { status, messages, content, credentials}
  }

  checkStatusOk({ status, statusCode}) {
    if (statusCode == "0" && status == "OK") return true 
    return false 
  }

  async login({ username, password}) {
    const params = this.getParams({ query: "login", userName: username, password: password })
    const { data } = await this.axios.get('serverSide/ErsM/ersMGateway.php', { params });
    let { status, content, messages } = data;
    if (!this.checkStatusOk(status)) {
      console.log(status, content, messages)
      throw new Error("Something is wrong", status)
    }
    this.credentials = { username, password }
    return content;
  }

  async getAppointmentDetail(idAppointment) {
    const params = this.getParams({ action: "getAppointmentDetail", idAppointment})
    const { data } = await this.axios.get('serverSide/ErsM/bookings/appointment.php', { params });
    let { status, content, messages } = data;
    if (!this.checkStatusOk(status)) {
      console.log(status, content, messages)
      throw new Error("Something is wrong", status)
    }
  }

  async getOpenTransactions() {
    const params = this.getParams({ action: "getOpenTransactions" })
    const { data } = await this.axios.get('serverSide/ErsM/payments/payments.php', { params });
    let { status, content, messages } = data;
    if (!this.checkStatusOk(status)) {
      console.log(status, content, messages)
      throw new Error("Something is wrong", status)
    }
    return content;
  } 

  async startIdealPayment({ transactions, issuerId }) {
    let includeTransactions = '[' + transactions.join() + ']';
    let transactionCount = transactions.length; 
    const params = this.getParams({ action: "startIdealPayment", includeTransactions, transactionCount, issuerId, api: 'y', idealReturnUrlSet: '2'  });
    const { data } = await this.axios.get('serverSide/ErsM/payments/payments.php', { params });
    let { status, content, messages } = data;
    if (!this.checkStatusOk(status)) {
      console.log(status, content, messages)
      throw new Error("Something is wrong", status)
    }
    console.log(content)
    return content 

    // let urlParams =
    //   `serverSide/ErsM/payments/payments.php?` +
    //   `&issuerId=${options.issuerId}` +
    //   `&transactionCount=${options.transactionCount}` +
    //   `&api=y`;

  }
} 


export default new ersMob()