<template>
  <button :disabled="disabled">
    <slot/>
  </button>
</template>


<script>
export default {
  props: {
    type: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false 
    }
  }
}
</script>


<style scoped>



button {
  border: none;
  color:white;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  font-family:"Rajdhani", "DIN", sans-serif;
  text-transform:uppercase;
  font-weight:600;
  letter-spacing:.03em;
  display:block;
  height:40px;
  padding:0 10px;
  border-radius:1px;
  font-size:16px;

  text-align:center;
  line-height:40px;

  /* background-color:#232323; */
  background-color:#A86B15;
  transition:all .25s ease;
  /* outline:0px solid rgba(119,119,119,0); */

  margin-left: auto;
  margin-right: auto;
  width:  50%
} 


button:disabled,
button[disabled]{
  border: 1px solid #777777;
  background-color: #999999;
  color: #666666;
}





</style>