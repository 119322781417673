import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Payment from '../views/Payment.vue';
import iDealSuccess from '../views/iDealSuccess.vue';
import iDealFailure from '../views/iDealFailure.vue';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/betalen',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/iDealSuccess',
    name: 'iDealSuccess',
    component: iDealSuccess
  },
  {
    path: '/iDealFailure',
    name: 'iDealFailure',
    component: iDealFailure
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
