import axios from "axios";
var parser = new DOMParser();

class ersPub {
  constructor() {
    this.baseParams = {
      s: 1,
      c: 1,
      idCourse: 1,
      whatCourse: 1,
      testMode: 'p'
    }

    this.axios = axios.create({
      baseURL: "https://www.motorrijles-amsterdam.nl/apiV2.php",
      timeout: 60000,
    });
  }

  getParams(p) {
    return { ...this.baseParams, ...p }
  }

  unpackStatus(xml) {
    let status = xml.getElementsByTagName('status')[0]
    let text = status.textContent
    let code =  status.attributes[0].textContent
    
    return { code, text }
  }

  unpackMessages(xml) {
    let m = xml.getElementsByTagName('messages')[0].children
    let messages = {}
    for (let item of m) {
      messages[item.nodeName] = item.innerHTML;
    }
    return messages
  }

  unpackContent(xml) {
    let json = xml.getElementsByTagName('content')[0].textContent
    try {
      return JSON.parse(json)
    } catch (e) {   // invalid JSON 
      return null 
    }
  }

  unpackCredentials(xml) {
    try {
      let username = xml.getElementsByTagName('userId')[0].textContent
      let password = xml.getElementsByTagName('userPassword')[0].textContent
      return { username, password }
    } catch (error) {
      return null
    }
  }

  unpackXML(data) {
    let xml = parser.parseFromString(data, "application/xml");

    let messages = this.unpackMessages(xml)
    let content = this.unpackContent(xml)
    let status = this.unpackStatus(xml)
    let credentials = this.unpackCredentials(xml)

    return { status, messages, content, credentials}
  }

  checkStatusOk(status) {
    if (status.code == 0 && status.text == "OK") return true 
    return false 
  }


  async getAppts () {
    const params = this.getParams({ q: "getAppts", bAP: 60, swProf: 1 })
    const { data } = await this.axios.get('', { params });
    let { status, content } = this.unpackXML(data)
    if (!this.checkStatusOk(status)) {
      throw new Error("Something is wrong", status)
    }
    return content.appointmentSet[0].appts
  }

  async registerNewStudent({ appointment, name, email, telephone}) {

    const params = this.getParams({
      q: 'registerNewStudent', 
      firstName: name.split(' ')[0], 
      lastName: name.split(' ')[1],
      indEmail: email, 
      indMobile: telephone,
      idAppointment: appointment,
      indBirthDate: '01-01-1970', 
      cOnNoP: 'n', 
    })
    const { data } = await this.axios.get('', { params });
    let { status, content, messages, credentials } = this.unpackXML(data)
    if (!this.checkStatusOk(status)) {
      console.log(status, content, messages)
      throw new Error("Something is wrong", status)
    }
    this.credentials = credentials;
    return credentials
  }

  async checkEmailUnique(email) {
    if (!email) return false;

    const params = this.getParams({
      q: 'checkEmailUnique', 
      emailAddress: email, 
    })

    const { data } = await this.axios.get('', { params });
    let { status, content, messages } = this.unpackXML(data)
    if (!this.checkStatusOk(status)) {
      console.log(status, messages, content)
      throw new Error("Something is wrong", status)
    }

    if (content === 0) return false; // not unique
    if (content === 1) return true; // unique
    if (content === 2) return true; // not correct
    return false;
  }

    // let urlParams =
    //   `&q=registerNewStudent` +
    //   `&s=${context.idSchool}` +
    //   `&idCourse=${context.idCourse}` +
    //   `&whatCourse=${context.idCourse}`;

    // let params = [
    //   "firstName",
    //   "lastName",
    //   "indEmail",
    //   "indMobile",
    //   "tussenVoegsel",
    //   "initial",
    //   "idAppointment",
    //   "idAppts",
    //   "idPkUpLoc",
    //   "address1",
    //   "postcode",
    //   "town",
    //   "sofiNr",
    //   "indBirthDate",
    //   "studentLevel",
    //   "tAndC",
    //   "emtAndC",
    //   "idPackage",
    // ];


    // var result = await state.api.get(urlParams);
    // var status = await dispatch("ersGetStatusElement", result);
    // var messages = await dispatch("ersGetMessagesElement", result);
    // var content = await dispatch("ersGetContentElement", result);

    // if (status === "ERR") throw new Error("Registratie mislukt");

    // var loginDetails = await dispatch("ersGetLoginDetailsElement", result);

    // if (content === "1") throw new Error("Het e-mail adres is niet uniek.");
    // if (content === "2") throw new Error("Het e-mail adres is niet correct.");

    // // var registrationComplete = content.includes('Registratie compleet');
    // // if (!registrationComplete) return new Error("Registratie is mislukt");
    // if (!loginDetails) return new Error("Login gegevens niet beschikbaar");

    // commit("setContextProperty", {
    //   key: "loginDetails",
    //   value: loginDetails,
    // });
    // return loginDetails;
  // },


} 


export default new ersPub()