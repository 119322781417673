<template>
  <div id="app">

    <TheHeader/>


    <router-view/>
    
    <footer>
      <div class="footer--label">
          <img src="@/assets/img/footer/bovag.png"/>
          <img src="@/assets/img/footer/vrb.png"/>
          <img src="@/assets/img/footer/cbr.png">
      </div>
    </footer>


  </div>
</template>

<script>
import TheHeader from "./components/TheHeader";




export default {
  name: 'App',
  components: {
    TheHeader,

  }
}
</script>

<style>

html {
  scroll-behavior: smooth;
}

#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  background: #DEDEDE;
}

.section {
  height: 100%;
  padding-top: 20px;
}

.box {
  border-color: black;
  border-width: 2px;
  border-style: solid;
  margin: 10px;
}

body {
  height: 100%;
  margin: 0;
  font-size:16px;
  font-family:"Avenir Next", "Avenir", "Avenir Next W01", system-ui, sans-serif;
  color:#232323;
  background-color:white;
}

section {
 margin-left:auto;
 margin-right:auto;
 /* padding-left:10px;
 padding-right:10px; */
 /* max-width:660px; */
 font-size:17px;
 line-height:1.58;
 padding-bottom:74px;
 padding-top:74px;
}

.section--dark {
 color:white;
 -webkit-font-smoothing:antialiased;
 -moz-osx-font-smoothing:grayscale;
 max-width:none;
 background-color:#232323;
 border-bottom:1px solid #232323
}

.section--white {
  background-color: white;
}

.section--medium {
  background-color: #DEDEDE;
}






.motorcycle-image {
  height: auto; 
  width: auto; 
  max-width: 100%; 
  max-height: 100%;
}



.double-feature {
 display:flex;
 flex-direction:column;
 margin-bottom:24px
}

.double-feature__feature {
 margin-bottom:10px;
 background-color:rgba(255,255,255,0.1)
}

.including-box .double-feature__subtitle {
 font-family:"Rajdhani", "DIN", sans-serif;
 text-transform:uppercase;
 font-weight:600;
 letter-spacing:.03em;
 font-size:18px;
 line-height:1.1;
 letter-spacing:.07em;
 font-weight:600;
 opacity:.7
}

.including-box .double-feature__title {
 font-family:"Rajdhani", "DIN", sans-serif;
 text-transform:uppercase;
 font-weight:600;
 letter-spacing:.03em;
 margin-bottom:22px;
 font-size:22px;
 font-weight:bold;
 letter-spacing:.07em;
 line-height:1
}

.including-box {
 border-radius:2px;
 padding:10px
}

.media--reverse {
 display:flex;
 align-items:flex-start
}
.media--reverse :first-child {
 margin-right:10px
}
.media--reverse :nth-child(2) {
 flex-grow:1
}
.media--reverse :first-child {
 order:1;
 margin-right:0
}

.grid {
  background-color: #232323;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-rows: repeat(auto-fit, minmax(150px, 1fr));
  margin: auto;
}

.grid > div {
  display: grid;
}

.grid > div::before {
  content: " ";
  padding-top: 100%;
  display: block;
  grid-area: 1 / 1 / 2 / 2; 
}

.grid > div > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  grid-area: 1 / 1 / 2 / 2; 
  opacity: 0.5;
}

/* .square-gallery {
  display: grid;
  height: calc(100vh - 10px);
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /* grid-template-columns: repeat(2, 1fr); 
  grid-gap: 0px;
  background-color: #232323;
}

.square-gallery > img::before {
  content: "";
  padding-bottom: 100%;
  display: block;
} 

.gallery-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}*/

 .unit--title {
 font-family:"Rajdhani", "DIN", sans-serif;
 text-transform:uppercase;
 font-weight:600;
 letter-spacing:.03em;
 position:relative;
 padding-left:60px;
 font-size:20px;
 letter-spacing:.1em;
 border-bottom:1px solid rgba(255,255,255,0.4)
}

.unit--title span {
 display:block;
 position:absolute;
 left:0;
 float:left;
 width:60px
}

/*
.unit--lessons {
 margin:0;
 padding:0;
 list-style-type:none
}
.unit--lesson {
 margin:0;
 padding:0;
 border-bottom:1px solid rgba(255,255,255,0.1)
}
section .unit--lesson,
.section--dark .unit--lesson {
 margin-bottom:0
}
.unit--lesson-title {
 display:block;
 position:relative;
 padding:13px 0 11px 116px;
 text-decoration:none;
 line-height:1.2;
 transition:background-color .2s ease
}
.unit--lesson-title span:first-child {
 position:absolute;
 left:60px
}
a.unit--lesson-title:hover {
 background-color:rgba(255,255,255,0.06)
}
a.unit--lesson-title:before {
 content:url(./assets/img/video-icon.png);
 position:absolute;
 left:5px
}
.unit--lesson-description {
 margin:0 10px;
 border-radius:2px;
 height:0;
 padding-left:36px;
 overflow:hidden;
 font-size:16px;
 background-color:rgba(255,255,255,0.14);
 box-shadow:inset 0 1px 3px rgba(0,0,0,0.1);
 cursor:pointer
}
section .unit--lesson-description,
.section--dark .unit--lesson-description {
 margin-bottom:0
}

.unit--lesson-description.is-opened {
 margin-bottom:20px
}
.unit--lesson-description li {
 margin:0 15px
}
.unit--lesson-description li:first-child {
 margin-top:15px
}
.unit--lesson-description li:last-child {
 margin-bottom:15px
} */

/* COPY PASTE FROM LEARNUI.DESIGN */
 
section h2,
.section--dark h2 {
 font-family:"Rajdhani", "DIN", sans-serif;
 text-transform:uppercase;
 font-weight:600;
 letter-spacing:.03em;
 position:relative;
 margin-bottom:16px;
 padding-bottom:20px;
 font-size:28px;
 font-weight:600;
 line-height:1.14
}
section h2:after,
.section--dark h2:after {
 position:absolute;
 bottom:0;
 left:0;
 content:url(./assets/img/raj-underline.png)
}

section p,
.section--dark p,
section ul,
.section--dark ul {
 margin-bottom:22px
}
section ul li,
.section--dark ul li {
 position:relative;
 list-style-type:none
}
section ul li:before,
.section--dark ul li:before {
 content:"»";
 position:absolute;
 left:-34px
}
section li,
.section--dark li {
 margin-bottom:14px
}
/* section a,
.section--dark a {
 color:#232323
} */
section a, {
 color:#232323
}
.support-page section,
.support-page .section--dark {
 font-size:16px;
 line-height:1.62
}

.support-page section h1,
.support-page .section--dark h1,
.support-page section h2,
.support-page .section--dark h2 {
 font-family:"freight-text-pro", "FreightText Pro", "Georgia", serif;
 letter-spacing:0;
 text-transform:none;
 font-weight:600;
 line-height:1.1;
 margin-bottom:16px;
 padding-bottom:0;
 line-height:1
}
.support-page section h1:after,
.support-page .section--dark h1:after,
.support-page section h2:after,
.support-page .section--dark h2:after {
 content:none
}
.support-page section h1,
.support-page .section--dark h1 {
 margin-top:24px;
 font-size:28px
}

.support-page section h2,
.support-page .section--dark h2 {
 margin-top:32px;
 font-size:22px
}

.support-page section a:not(.btn):not(.header__btn),
.support-page .section--dark a:not(.btn):not(.header__btn) {
 text-decoration:underline;
 color:#109CBF
}
.support-page section figure,
.support-page .section--dark figure {
 margin:32px 0
}

.support-page section figure>img,
.support-page .section--dark figure>img {
 display:block;
 margin:0 auto;
 max-width:100%
}

.section--dark {
 color:white;
 -webkit-font-smoothing:antialiased;
 -moz-osx-font-smoothing:grayscale;
 max-width:none;
 background-color:#232323;
 border-bottom:1px solid #232323
}
.section--dark a {
 color:white
}
.section--dark h2 {
 padding-top:48px
}

.not-alone {
 font-size:17px;
 line-height:1.58;
 margin-top: -72px;
 margin-left:auto;
 margin-right:auto;
 padding-left:10px;
 padding-right:10px;
 max-width: min(740px, 90vw);
 position:relative;
 top:171px;
 padding-bottom:10px;
 background-color:#333;
 box-shadow:0 2px 14px rgba(0,0,0,0.2)
}

.not-alone h2 {
 font-family:"Rajdhani", "DIN", sans-serif;
 text-transform:uppercase;
 font-weight:600;
 letter-spacing:.03em;
 position:relative;
 margin-bottom:16px;
 padding-bottom:20px;
 font-size:28px;
 font-weight:600;
 line-height:1.14
}
.not-alone h2:after {
 position:absolute;
 bottom:0;
 left:0;
 content:url(./assets/img/raj-underline.png)
}

.not-alone p,
.not-alone ul {
 margin-bottom:22px
}
.not-alone ul li {
 position:relative;
 list-style-type:none
}
.not-alone ul li:before {
 content:"»";
 position:absolute;
 left:-34px
}
.not-alone li {
 margin-bottom:14px
}
.not-alone a {
 color:#232323
}
.support-page .not-alone {
 font-size:16px;
 line-height:1.62
}

.support-page .not-alone h1,
.support-page .not-alone h2 {
 font-family:"freight-text-pro", "FreightText Pro", "Georgia", serif;
 letter-spacing:0;
 text-transform:none;
 font-weight:600;
 line-height:1.1;
 margin-bottom:16px;
 padding-bottom:0;
 line-height:1
}

.support-page .not-alone h1:after,
.support-page .not-alone h2:after {
 content:none
}

.support-page .not-alone h1 {
 margin-top:24px;
 font-size:28px
}

.support-page .not-alone h2 {
 margin-top:32px;
 font-size:22px
}

.support-page .not-alone a:not(.btn):not(.header__btn) {
 text-decoration:underline;
 color:#109CBF
}

.support-page .not-alone figure {
 margin:32px 0
}

.support-page .not-alone figure>img {
 display:block;
 margin:0 auto;
 max-width:100%
}

.not-alone h2 {
 font-family:"freight-text-pro", "FreightText Pro", "Georgia", serif;
 letter-spacing:0;
 text-transform:none;
 font-weight:600;
 line-height:1.1;
 position:relative;
 margin-bottom:20px;
 padding:6px 0 10px
}

.not-alone h2:after {
 content:"";
 position:absolute;
 bottom:0;
 display:block;
 height:4px;
 width:35px;
 background-color:rgba(255,255,255,0.7)
}

.not-alone img {
 float:left;
 height: 150px;
 margin-right:10px;
 border-radius: 50%;
}








.header__logo {
  float: left; 
  vertical-align: middle;
 /* height:48px; 
 display:inline-block; */
}

.header__logo svg {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  padding-bottom: 3px;
}

.header__contact {
  float: right; 
  vertical-align: middle;
  margin: 10px ;
  background: rgba(30,190,165,0.74);
  background: #242424;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
  opacity: 0.9;
  font-family: DINAlternate-Bold;
  font-size: 20px;
  color: white;
  letter-spacing: 0;
}

.header__contact svg {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  padding-bottom: 3px;
}

.header__logo a {
 display:inline-block;
 height:100%
}
.header__notice {
 display:none
}

.header__notice a:not(.header__btn) {
 display:inline-block;
 height:100%
}
.header__notice a:not(.header__btn):hover {
 background-color:rgba(255,255,255,0.04)
}
.header__open-date {
 display:none
}

.header__get-notified {
 display:none
}

.header__countdown {
 display:none
}

.header__nav {
 margin:0;
 padding:0;
 list-style-type:none;
 display:flex;
 display:flex;
 height:42px;
 background-color:#262626;
 box-shadow:inset 0 1px 3px rgba(0,0,0,0.5);
 overflow-x:auto
}

.header__link {
 font-family:"Rajdhani", "DIN", sans-serif;
 text-transform:uppercase;
 font-weight:600;
 letter-spacing:.03em;
 display:block;
 position:relative;
 padding:0 8px;
 height:100%;
 font-size:16px;
 line-height:42px;
 white-space:nowrap;
 background-color:transparent;
 transition:background-color 0.1s ease
}
.header__link,
.header__link:hover,
.header__link:focus,
.header__link:active {
 text-decoration:none
}

.header__link:hover,
.header__menu-parent:focus-within .header__link {
 background-color:rgba(255,255,255,0.1)
}
.header__link--active:after {
 content:"";
 position:absolute;
 display:block;
 bottom:0;
 left:8px;
 right:8px;
 height:4px;
 background-image:linear-gradient(to bottom, #FF9F2F, #A86B15)
}
.blue-theme .header__link--active:after {
 background-image:linear-gradient(to bottom, #4AC4E2, #109CBF)
}
.header__login {
 justify-self:flex-end;
 margin-left:auto;
 opacity:.7
}
.header__btn {
 margin-top:6px;
 margin-right:8px;
 align-self:center
}

.header__btn.header__enroll-now {
 margin:0 6px
}

.header__menu-parent:hover .header__link,
.header__menu-parent:focus-within .header__link {
 background-color:rgba(255,255,255,0.1)
}

.header__menu-parent:hover .header__menu,
.header__menu-parent:focus-within .header__menu {
 visibility:visible;
 transition-delay:0s
}

.header__menu-parent .header__link[href="#"] {
 cursor:default
}

.header__menu {
 margin:0;
 padding:0;
 list-style-type:none;
 color:white;
 -webkit-font-smoothing:antialiased;
 -moz-osx-font-smoothing:grayscale;
 visibility:hidden;
 position:absolute;
 top:90px;
 left:0;
 margin:2px 8px;
 max-width:382px;
 width:calc(100% - 16px);
 border-radius:2px;
 background-color:#232323;
 box-shadow:0 2px 8px -2px rgba(0,0,0,0.3);
 z-index:2;
 transition:0.0s 0.1s
}
.header__menu a {
 color:white
}

.header__menu li {
 border-bottom:1px solid rgba(255,255,255,0.2)
}

.header__menu li a {
 font-family:"Rajdhani", "DIN", sans-serif;
 text-transform:uppercase;
 font-weight:600;
 letter-spacing:.03em;
 display:block;
 position:relative;
 padding:8px 8px 7px 40px;
 font-size:15px;
 white-space:nowrap;
 transition:background-color 0.2s ease
}

.header__menu li a,
.header__menu li a:hover,
.header__menu li a:focus,
.header__menu li a:active {
 text-decoration:none
}
.header__menu li a:hover {
 background-color:rgba(255,255,255,0.1)
}
.header__menu li:last {
 border-bottom:none
}

.header-spacer {
 height:24px
}

.survey-link img {
 max-width:100%
}
.header--extra {
 align-items:center
}
.header--extra .header__countdown {
 width:auto;
 line-height:inherit;
 padding:6px 0
}

.header--extra .header__get-notified {
 margin-top:8px
}

.header__logo a {
 display:inline-block;
 height:100%
}

.header__contact {
  float: right; 
  vertical-align: middle;
  margin: 10px ;
  background: rgba(30,190,165,0.74);
  background: #242424;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
  opacity: 0.9;
  font-family: DINAlternate-Bold;
  font-size: 20px;
  color: white;
  letter-spacing: 0;
}

.header__contact svg {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  padding-bottom: 3px;
}

header {
  height:60px; 
  color:white;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  padding-left:env(safe-area-inset-left);
  padding-right:env(safe-area-inset-right);
  position:relative;
  background-color:#171717;
}


button,
input,
select,
textarea {
 font-size:100%;
 margin:0;
 vertical-align:baseline;
 *vertical-align:middle
}
button,
input {
 line-height:normal
}
button,
select {
 text-transform:none
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
 -webkit-appearance:button;
 cursor:pointer;
 *overflow:visible
}
button[disabled],
html input[disabled] {
 cursor:default
}
input[type="checkbox"],
input[type="radio"] {
 box-sizing:border-box;
 padding:0;
 *height:13px;
 *width:13px
}
input[type="search"] {
 -webkit-appearance:textfield;
 box-sizing:content-box
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
 -webkit-appearance:none
}
button::-moz-focus-inner,
input::-moz-focus-inner {
 border:0;
 padding:0
}

.btn,
.header__btn {
 color:white;
 -webkit-font-smoothing:antialiased;
 -moz-osx-font-smoothing:grayscale;
 font-family:"Rajdhani", "DIN", sans-serif;
 text-transform:uppercase;
 font-weight:600;
 letter-spacing:.03em;
 display:inline-block;
 height:40px;
 padding:0 10px;
 border-radius:1px;
 font-size:16px;
 text-transform:uppercase;
 text-align:center;
 font-weight:700;
 line-height:40px;
 background-color:#232323;
 transition:all .25s ease;
 outline:0px solid rgba(119,119,119,0)
}
.btn a,
.header__btn a {
 color:white
}
.btn,
.header__btn,
.btn:hover,
.btn:focus,
.btn:active {
 text-decoration:none
}
.btn:focus:not(:active),
.header__btn:focus:not(:active) {
 outline:3px solid rgba(119,119,119,0.5)
}
.btn--fire,
.header__btn {
 background-image:linear-gradient(#FF9F2F, #A86B15)
}
.btn--fire:hover,
.header__btn:hover,
.btn--fire:focus,
.header__btn:focus {
 background-image:linear-gradient(#EB8826, #EB4E1E)
}
.blue-theme .btn--fire,
.blue-theme .header__btn {
 background-image:linear-gradient(#4AC4E2, #109CBF)
}
.blue-theme .btn--fire:hover,
.blue-theme .header__btn:hover,
.blue-theme .btn--fire:focus,
.blue-theme .header__btn:focus {
 background-image:linear-gradient(#38bedf, #0e8dac)
}
.btn--orange {
 background-color:#A86B15
}
.btn--orange:hover,
.btn--orange:focus {
 background-color:#f24100
}
.btn--blue {
 background-color:#109CBF
}
.btn--blue:hover,
.btn--blue:focus {
 background-color:#0e8dac
}
.btn--cta {
 padding:0 16px;
 height:48px;
 line-height:48px;
 font-size:16px;
 letter-spacing:.08em;
 border:1px solid rgba(0,0,0,0.1);
 border-radius:0;
 box-shadow:0 3px 12px -3px rgba(0,0,0,0.2)
}
.btn--cta:hover,
.btn--cta:focus {
 box-shadow:0 6px 24px -6px rgba(0,0,0,0.4)
}

.btn--full-width {
 display:block;
 width:100%;
 text-align:center
}
button.btn,
button.header__btn,
input[type=submit],
input[type=submit].btn--full-width {
 border:none
}
.btn--center {
 position:absolute;
 left:50%;
 transform:translateX(-50%);
 position:relative
}

.btn--disabled {
 pointer-events:none;
 opacity:.5
}

.hero-image {
  position: relative;
  height: 80vh;
  background-image:
    linear-gradient(180deg, rgba(2,0,36,0) 60%, rgba(35,35,35,1) 100%),
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./assets/img/hero_background.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-text button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 10px 25px;
  color: black;
  background-color: #ddd;
  text-align: center;
  cursor: pointer;
}

.hero-text button:hover {
  background-color: #555;
  color: white;
}

.hero__title {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color:white;
  text-align: center; */

  font-style: normal;
  font-weight: bold;
  color: #eee;
  font-size: 11vmin;
  letter-spacing: 0.03em;
  line-height: 1;
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.8);


  /* font-family:"Rajdhani", "DIN", sans-serif;
  text-transform:uppercase;
  font-size:48px;
  opacity:.75; */
}

.hero__subtitle {
  position: absolute;
  top: 50%;
  left: 50%;

  color:white;
  font-family:"Rajdhani", "DIN", sans-serif;
  text-transform:uppercase;
  font-weight:600;
  letter-spacing:.03em;
  font-size:16px;
  opacity:.75
}

.hero--cta {
  display: inline-block;
  vertical-align: middle;
  margin: 30px;
  padding: auto;
}

.hero--cta svg {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
}



#binnenkant-rijschool {
  width: 90%;
  margin: auto;
  display: block; 
}







.what-to-know {
 font-size:17px;
 line-height:1.58;
 margin-top: -76px;
 margin-left:auto;
 margin-right:auto;
 padding-left:10px;
 padding-right:10px;
 max-width:740px;
 position:relative;
 top:114px;
 padding-bottom:10px;
 background-color:#eee;
 box-shadow:0 2px 14px rgba(0,0,0,0.2)
}

.what-to-know h2 {
 font-family:"Rajdhani", "DIN", sans-serif;
 text-transform:uppercase;
 font-weight:600;
 letter-spacing:.03em;
 position:relative;
 margin-bottom:16px;
 padding-bottom:20px;
 font-size:28px;
 font-weight:600;
 line-height:1.14
}

.what-to-know h2:after {
 position:absolute;
 bottom:0;
 left:0;
 content:url(./assets/img/raj-underline.png)
}

.what-to-know p,
.what-to-know ul {
 margin-bottom:22px
}

.what-to-know ul li {
 position:relative;
 list-style-type:none
}

.what-to-know ul li:before {
 content:"»";
 position:absolute;
 left:-34px
}

.what-to-know li {
 margin-bottom:14px
}

.what-to-know a {
 color:#232323
}

.what-to-know h2 {
 font-family:"freight-text-pro", "FreightText Pro", "Georgia", serif;
 letter-spacing:0;
 text-transform:none;
 font-weight:600;
 line-height:1.1;
 position:relative;
 margin-bottom:20px;
 padding:6px 0 10px
}

.what-to-know h2:after {
 content:"";
 position:absolute;
 bottom:0;
 display:block;
 height:4px;
 width:35px;
 background-color: #A86B15;
 /* background-color:rgba(255,255,255,0.7) */
}

.what-to-know img {
 float:left;
 height: 150px;
 margin-right:10px;
 border-radius: 50%;
}

.price-tag {
  position: relative;
  bottom: 25px;
  height: 110px;
  float: right;
  transform: rotate(10deg)
}



/* .ck_control_group.floatl--active .floatl__label {
 background-color:transparent;
 background:none
}
.ck_control_group input.floatl__input {
 font-family:inherit;
 width:100%;
 height:44px;
 font-size:16px
} */






.section--container {
  max-width:660px;
  margin-left:auto;
  margin-right:auto;
  padding-left:10px;
  padding-right:10px;
  font-size:17px;
  line-height:1.58;
}

footer {
  background-color:#121212;
  padding-bottom:50px;
}

.footer--label {
  padding: 20px;
  display: flex;
  min-height:75px;
  max-width: min(75%, 550px);
  background-color: white;
  margin-left:auto;
  margin-right:auto;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.footer--label > img {
  width: 20%;
  margin-left: auto;
  margin-right:auto;
  align-self: center;
  justify-content:space-around

}

.diagonal--light {
  position: relative;
}

.diagonal--light:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  transform-origin: top left;
  transform: skewY(4deg);
  z-index: -2;
  box-shadow: inset 0 7px 9px -10px #000;
}

.diagonal--medium {
  position: relative;
}

.diagonal--medium:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-color: #DEDEDE;
  top: 0;
  bottom: 0;
  left: 0;
  transform-origin: top left;
  transform: skewY(4deg);
  z-index: 0;
  box-shadow: 
    inset 0px 11px 10px -10px #000,
    inset 0px -11px 10px -10px #000; 
}

.diagonal--dark {
  position: relative;
}

.diagonal--dark:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-color:#232323;
  top: 0;
  bottom: 0;
  left: 0;
  transform-origin: top left;
  transform: skewY(4deg);
  z-index: -1;
  box-shadow: 
    inset 0px 11px 10px -10px #000,
    inset 0px -11px 10px -10px #000; 
}


#rijschool {
  position: relative;
  z-index: 1;
  box-shadow: inset 0 -7px 9px -10px #000;
}

/* .google-map {
  height: 300px; 
  max-width:769px;
  position:relative;
  bottom:-40px;
  background-color:#333;
  box-shadow:0 2px 14px rgba(0,0,0,0.2)
} */

#map {
  position: relative;

  /* font-size: 17px;
  line-height: 1.58; */
  /* margin-top: -76px; */
  /* max-width:769px; */ 
  height: 450px; 
  width: 100%;
  bottom: -72px;
  padding-bottom: 10px;
  background-color: #eee;
  box-shadow: inset 0 -7px 9px -10px #000;

  /* box-shadow: 0 2px 14px rgba(0,0,0,0.2); */
}

#proefles{
  position: relative;
  z-index: 10;
}

#proefles:before {
  position: absolute;
  content: '';
  width: 50.1%;
  height: 150px;
  top: -60px;
  left: 0;
  z-index: 0;
  transform: skewY(8deg); 
  background: inherit;
  box-shadow: inset 0 7px 9px -10px #000;
}

#proefles:after {
  position: absolute;
  content: '';
  width: 50.1%;
  height: 150px;
  top: -60px;
  right: 0;
  z-index: 0;
  transform: skewY(-8deg); 
  background: inherit;
  box-shadow: inset 0 7px 9px -10px #000;
}





#references {
  padding-top: 216px;
}


#faq {
  padding-top: 216px;
  padding-bottom: 216px;
}

#book_now {
  padding-bottom: 144px;
  position: relative;
  z-index: 10;
  background-color: #232323;
  color: white;

}

#book_now:before {
  position: absolute;
  content: '';
  width: 50.1%;
  height: 300px;
  top: -80px;
  left: 0;
  background: #232323;
  z-index: -2;
  transform: skewY(8deg); 
  box-shadow: inset 0 7px 9px -10px #000;
}

#book_now:after {
  position: absolute;
  content: '';
  width: 50.1%;
  height: 300px;
  top: -80px;
  right: 0;
  background: #232323;
  z-index: -2;
  transform: skewY(-8deg);
  box-shadow: inset 0 7px 9px -10px #000;
}


.background--dark {
  background-color: #232323;
}

.background--medium {
  background-color: #DEDEDE;
}

.background-light {
  background-color: white;
}

.top-box
{
    box-shadow: inset 0 7px 9px -10px #000;
}

.left-box
{
    box-shadow: inset 7px 0 9px -10px #000;
}

.right-box
{
    box-shadow: inset -7px 0 9px -10px #000;
}

.bottom-box
{
  box-shadow: inset 0 -7px 9px -10px #000;
}


.masthead {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100vw;
  height: 70vh; /* if you don't want it to take up the full screen, reduce this number */
  overflow: hidden;
  background:
    linear-gradient(180deg, rgba(2,0,36,0) 60%, rgba(35,35,35,1) 100%),
    radial-gradient(ellipse at center, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.65) 100%),
    linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("./assets/img/hero4.jpg");
  background-repeat: no-repeat;
  background-size: cover!important;
  background-position: auto 100%;
  /* background-position: 35% 10%; */
  padding: -10px !important;


}


.masthead:before {
  position: absolute;
  content: '';
  width: 50.1%;
  height: 100px;
  bottom: -35px;
  left: 0;
  background: #232323;
  z-index: 1;
  transform: skewY(8deg); 
  box-shadow: inset 0 7px 9px -10px #000;
}

.masthead:after {
  position: absolute;
  content: '';
  width: 50.1%;
  height: 100px;
  bottom: -35px;
  right: 0;
  background: #232323;
  z-index: 1;
  transform: skewY(-8deg); 
  box-shadow: inset 0 7px 9px -10px #000;
}

h1 {
  color: #eee;
  letter-spacing: 0.03em;
  line-height: 1;
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.8);

  font-family:"Rajdhani", "DIN", sans-serif;
  text-transform:uppercase;
  font-size:48px;
  opacity:.95;

}
    

      

.double-feature {
 display:flex;
 flex-direction:column;
 margin-bottom:24px
}

@media (min-width: 769px) {
 .double-feature {
  flex-direction:row;
  margin:0 -70px 48px
 }
}
@media (min-width: 1024px) {
 .double-feature {
  margin:0 -160px 64px
 }
}
.double-feature__feature {
 margin-bottom:10px;
 background-color:rgba(255,255,255,0.1)
}
@media (min-width: 769px) {
 .double-feature__feature {
  flex-basis:50%
 }
 .double-feature__feature:first-child {
  margin-right:10px
 }
 .double-feature__feature:last-child {
  margin-left:10px
 }
}




</style>













