<template>
  <main>
    <section aria-label="Onze rijschool" class="section--medium">
      <div class="section--container">

          <h2>Proefles geboekt!</h2>

          <div class="confirmation-wrapper"> 
            <p>Leuk dat je een proefles bij ons boekt. De details krijg je per mail toegestuurd!</p>
          </div>
      </div>
    </section>
  </main>
</template>

<script>
// import ersPub from "@/api/ersPub.js"
import ersMob from "@/api/ersMob.js"

export default {
  async created () {
    let { issuers, transactions, basketTotal } = await ersMob.getOpenTransactions()
    this.issuers = issuers.issuers;
    this.transactions = transactions
    this.total = basketTotal
  },
  data () { 
    return {
      result: {},
      issuers: [],
      issuerId: null, 
      transactions: [],
      total: null,
    }
  },
  computed: {
    transaction() {
      if (this.transactions.length > 0) {
        return this.transactions[0]
      } else {
        return {}
      }
    }
  },
  methods: {
    async submitForm() {
      let transactions = this.transactions.map(t => t.idTransaction);
      let issuerId = this.issuerId;
      if (typeof issuerId != "string") {
        console.error("No valid issuer selected")
        return 
      }
      let result = await ersMob.startIdealPayment({ transactions, issuerId });
      if (!result) {
        console.error("No resolt from payment initiation")
        return 
      }
      console.log(`Redirecting to iDeal Payment link ${result}`);
      
      // window.location.replace(result);
    }
  }
}
</script>


<style scoped>
.confirmation-wrapper {
  border-radius: 5px;
  background-color: #fff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  padding-bottom: 50px;
}
</style>