<template> 
  <div class="component--input">
    <ValidationProvider 
      :rules="rules" 
      v-slot="{ errors, classes, ariaInput }"
    >
      <div class="floating-label">
        <input 
          autocomplete="off"
          :class="classes"
          :required="required" 
          :placeholder="label" 
          :name="label.toLowerCase()" 
          v-model="cValue"
          v-bind="ariaInput"
        />
        <label :for="label.toLowerCase()">{{ label }}</label>
      </div>
      <span class="error">{{ errors[0] }}</span>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true 
    },
    required: {
      type: Boolean,
      defeault: false
    },
    label: {
      type: String,
      default: null 
    },
    rules: {
      type: String,
      default: '',
    },  
  },
  computed: {
    cValue: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
}
</script>


<style scoped>
.component--input {
  margin-bottom: 15px;
}

input {
  font-size: 16px;
  padding: 10px 10px;
  height: 46px;
  border: 2px solid #aaa;
  border-radius: 4px;
  background: #eee;
  box-sizing: border-box;
  transition: all 0.3s linear;
  color: #000;
  font-weight: 400;
  -webkit-appearance: none;
}

input.passed {
  background: url(../../assets/img/icons/check.svg) no-repeat 95% 50% #eee;
  background-size: 25px;
}

input.failed {
  background: url(../../assets/img/icons/clear.svg) no-repeat 95% 50% #eee;
  background-size: 25px;
}

label {
  font-size: 12.5px;
  color: #000;
  opacity: 0.8;
  font-weight: 400;
}

span .error {
  display:inline-block;
  height: 16px;
  font-size: 12px;
  color: grey;
}

.floating-label {
  position: relative;
  width: 100%;
}

.floating-label label {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
  padding-left: 12px;
}

.floating-label input:not(:placeholder-shown) {
  padding: 28px 10px 12px 10px;
}

input:focus {
  border: solid 2px #A86B15;
  outline: 0;
  box-shadow: 0 2px 6px -8px rgba(182, 157, 230, .45);
}

.floating-label input {
  width: 100%;
  margin-left: auto;
  display: flex;
}

.floating-label input:not(:placeholder-shown) + label {
  transform: translateY(-10px);
  opacity: 0.7;
}

.floating-label input:valid:not(:placeholder-shown) + label + .icon svg {
  opacity: 1;
}

/* .floating-label input:valid:not(:placeholder-shown) + label + .icon svg path {
  fill: #b69de6;
} */

.floating-label input:not(:valid):not(:focus) + label + .icon {
  animation-name: shake-shake;
  animation-duration: 0.3s;
}

@keyframes shake-shake {
  0% {
    transform: translateX(-3px);
  }
  20% {
    transform: translateX(3px);
  }
  40% {
    transform: translateX(-3px);
  }
  60% {
    transform: translateX(3px);
  }
  80% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(0px);
  }
}


/* input:required:invalid, input:focus:invalid {
  border: solid 3px #cd5c5c;
  outline: 0;
  box-shadow: 0 2px 6px -8px rgba(182, 157, 230, .45);
} 

.floating-label .icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 56px;
  width: 44px;
  display: flex;
} 

.floating-label .icon svg {
  height: 30px;
  width: 30px;
  margin: auto;
  opacity: 0.15;
  transition: all 0.3s ease;
}

.floating-label .icon svg path {
  transition: all 0.3s ease;
} */
 
</style>