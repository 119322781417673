<template>
  <div class="review">
    <div class="review--header">
      <img :src="image" :alt="name"> 
      <h3>{{ name }}</h3>
      <h4>{{ year }}</h4>
    </div>
    <p>{{ review }}.</p>
    <hr>
  </div>
</template>


<script>
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: ""
    },
    review: {
      type: String,
      default: ""
    },
    year: {
      type: String,
      default: ""
    },
  }
}
</script>

<style scoped>
.review {
 font-size:17px;
 padding-left:10px;
 padding-right:10px;
 max-width:740px;
 margin-bottom: 30px;
}

.review hr {
  width: 80%;
  opacity: 0.3;
}

.review h3 {
 font-family:"DIN", sans-serif;
 position:relative;
 margin-bottom: 0px;
 opacity:0.8;
 /* margin-bottom:16px; */
 /* padding-bottom:20px; */
}

.review h4 {
 font-family:"DIN", sans-serif;
 position:relative;
 margin-top: 0px;
 opacity:0.3;
 /* margin-bottom:16px; */
 /* padding-bottom:20px; */
}


.review h3:after {
  z-index:-1;
  position:absolute;
  top:-10px;
  left:225px;
  opacity:1;
  content:url(../assets/img/testimonials/quote.png)
}

.review p {
  margin-bottom:22px
}

.review--header {
  margin-bottom: 25px;
}

.review img {
  width:75px;
  height:75px;
  border-radius:50%;
  background-position: center;
  background-size:100% auto;
  background-repeat: no-repeat;
  float: left;
  margin-right: 25px;
  filter: grayscale(100%);
}

</style>