<template>
 <main>
  <section class="masthead" role="img" aria-label="Image Description">
        <h1>
          "Eigenwijs en eigengereid.<br/>Niet je rijbewijs halen, maar leren rijden."
        </h1>

        <button class="btn btn--orange btn--cta hero--cta" >
          <a href="#book_now">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-100 -30 100 200">
              <g fill="#FFF">
                <path transform="scale(-1,1) scale(.4) translate(-130 -150)" d="m276.62867,166.53801c-5.09164,.002-25.63728,2.14479-28.02556,23.12108-.309,.30899 0.30027,9.70885 0.30027,9.70885l4.6042,0 20.6188-2.70246 0.30028,20.91907-22.22026,16.71525 3.10282,12.41131 53.04841-38.83541 0.30027-18.81716c0,0-4.23873-22.42044-30.92823-22.42044 0-.0386-.37363-.10044-1.101-.10008z m33.1302,50.84636c-2.68464-.0666-5.81118,.62944-9.50865,2.90265-.309-1e-5-53.74903,40.13659-53.74903,40.13659l-30.32765,0c-1e-5,1e-5-12.51141-1.54082-12.51141,12.7116-1e-5,0-1.13091,13.31213 11.01004,13.31213l43.94007,0c8.99638-6.68777 18.07032-13.17395 27.02464-19.91816l28.42594,23.02099c0,1e-5-43.33954,19.71801-43.33954,19.71801-7.93785,3.77049-9.83114,12.71141-6.00548,20.01827l37.63434,67.06114c0,0 8.21745,9.38034 17.61605,2.40219 0.2832-.2832 11.4819-6.70653 5.40495-18.21659-.2961,0-23.92181-45.24125-23.92181-45.24125-1.98444-3.37362-2.09277-7.86528 2.70246-10.00914 0,0 57.3523-25.42321 57.3523-25.42321 4.36578-1.98449 7.81065-10.47041 0.90081-19.11745 0,10e-6-43.4396-59.15393-43.4396-59.15393 0,0-3.30218-4.05741-9.20843-4.20384z m-116.60632,85.67816c-32.30291,0-58.35321,26.43779-58.35321,58.75357-1e-5,32.27717 26.0503,58.25312 58.35321,58.25312 32.3158,3e-5 58.75357-25.96308 58.75357-58.25312 0-32.30288-26.43778-58.75357-58.75357-58.75357z m213.29452,0c-32.30291,0-58.35322,26.43779-58.35322,58.75357 0,32.27717 26.05028,58.25312 58.35322,58.25312 32.30294,3e-5 58.75358-25.96308 58.75358-58.25312 0-32.30288-26.45067-58.75357-58.75358-58.75357z m-213.29452,17.31581c22.81418,0 41.43778,18.62358 41.43778,41.43776 1e-5,22.80132-18.62361,41.03741-41.43778,41.03741-22.80129,0-41.03742-18.24899-41.03742-41.03741-1e-5-22.80131 18.24899-41.43776 41.03742-41.43776z m213.29452,0c22.80132,0 41.43779,18.62358 41.43779,41.43776 0,22.80132-18.6365,41.03741-41.43779,41.03741-22.80128,0-41.03744-18.24899-41.03744-41.03741 0-22.80131 18.249-41.43776 41.03744-41.43776z"/>
              </g>
            </svg> 
            Boek een Proefles
          </a>
        </button>

      

      </section>

      <section id="rijschool" aria-label="Onze rijschool" class="section--dark">
        <div class="section--container">
          <h2>Onze rijschool</h2>
            <p>Gevestigd op het Haarlemmerplein in Amsterdam vindt je wellicht wel de <em>gezelligste rijschool van Amsterdam</em>. Een daarvoor gelegen bruine kroeg kroeg is omgetoverd tot de standplaats van Rijschool Veilig Verkeer. Vanuit hier beginnen alle motorrijlessen. </p> 

            <img 
              id="binnenkant-rijschool"
              alt="Binnenkant van de rijschool"
              src="@/assets/img/rijschool.jpg" 
            >

            <p>Ook hebben we speciale jassen, broeken, helmen en zelfs rijschoenen <em>gratis voor jou te leen</em>. Op die manier gaan we veilig de weg op. Ook kun je materiaal bij ons aanschaffen met korting mocht je dat liever doen.</p>  

            <p>Onze rijschool werkt met een <em>online agendasysteem</em> waarin je <strong>zelfstandig</strong> en heel <strong>flexibel</strong> je lessen kunt plannen. Loop je dus toevallig langs is de kans groot dat onze instructeurs op pad zijn en tref je een gesloten rijschool. Boek daarom online, bel of stuur even een whatsappje.</p>

            <p>Uit principe voor de veiligheid richten wij ons enkel op A rijbewijzen. Wij geven dus geen motorijles voor het A1 en A2 rijbewijs. Naast motorrijlessen geven wij ook autorijles.</p>
        </div>
        <GoogleMap class="google-map"/>
      </section>


      <section id="proefles" aria-label="De proefles" class="background-light">
        <div class="section--container">

          <h2>De proefles</h2>  
          <Starburst class="price-tag" price="49"/>

          <h3>De proefles</h3>
          <p>De proefles duurt <em>twee uur</em> en begint op onze rijschool aan het Haarlemmerplein. Het eerste uur maken wij gebruik van een simulator <strong>simulator</strong>. Deze helpt om de <em>besturing van de motorfiets</em> op een rustige manier door te nemen en om <em>gevaarlijke situaties op de weg te herkenen</em>. Na dit eerste uur gaan we ons klaarmaken om de weg op te gaan, waarbij we met de motorfiets kleine stukjes gaan rijden. Het doel is om op een niveau te komen waarbij het onder begeleiding rijden naar een oefenterrein mogelijk is.</p>

          <h3>De instructeur</h3>

          <p>De instructeur tijdens jouw proefles is Marcel Elmers. Marcel is <em>oprichter</em> en <em>eigenaar</em> van de rijschool. Met al meer dan <strong>25 jaar ervaring</strong> begeleid Marcel mensen nog altijd met veel plezier naar hun motorrijbewijs. Hij is gemotiveerd om mensen op te leiden tot bedreven bestuurders; want bedreven bestuurders worden oud en het verkeer is al gevaarlijk genoeg.</p>

          <h3>De motorfiets</h3>
          <p>De Kawasaki Z650 is een heel <em>gebruiksvriendelijke</em> motorfiets die erg <strong>handzaam</strong> is, en <strong>licht</strong> in de omgang. Dit model is zeer geliefd bij leerlingen en daarom de <em>ideale motorfiets</em> om mee te lessen en examen meet doen. Vooral bij de het AVB examen zijn de speciale verichtingen goed te doen op de Kawasaki Z650.</p>


          <img src="@/assets/img/z650.png" class="motorcycle-image"/>

          <div class="what-to-know">
            <h2>Dit moet je weten...</h2>

            <ul>
              <li> 
                <p><strong>Heb je les?</strong> Kom dan altijd 15 minuten eerder zodat we meteen kunnen vertrekken als de les begint.</p>
              </li>
              <li> 
                <p><strong>Wat heb je nodig?</strong> Alle motorrijspullen zoals jas, broek, helm en schoenen kun je bij ons <em>gratis lenen</em>. Zorg wel dat je altijd een identiteitsbewijs op zak hebt.</p>
              </li>
              <li>
                <p><strong>Hoe lang?</strong> De les zelf duurt twee uur maar dit is exclusief de 15 minuten omkleden die je nodig hebt voor- en na de les. Zorg dus dat je op tijd bent want alleen zo heb je maximaal profijt van je les.</p>
              </li>
            </ul>


          </div>
        </div>

      </section>


      <section id="rijopleiding" aria-label="De rijopleiding" class="section--dark">
        <div class="section--container">

          <h2>Onze Rijopleiding</h2>
          <p>De rijopleiding is <em>onderverdeeld</em> in 6 verschillende <strong>niveaus</strong>, om op examen-niveau te komen moet je elk niveau beheersen. Tijdens jouw rijlopleiding zul je drie verschillende examens afleggen.</p>
          <ul>
            <li>Theorie-examen</li>
            <li>Praktijkexamen voertuigbeheersing (AVB)</li>
            <li>Praktijkexamen verkeersdeelname (AVD)</li>
          </ul>

            <div class="double-feature">

              <div id="videotraining" class="double-feature__feature including-box">
                <div class="media--reverse">
                  <img src="@/assets/img/video-series-icon.png" alt="Learn UI Design Video Series">
                  <div>
                      <p class="double-feature__subtitle">ONDERDEEL I.</p>
                      <h3 class="double-feature__title">Videotraining</h3>
                  </div>
                </div>
                <p>De videotraining bestaat uit 40 instructievideos die je thuis op je gemak kan bekijken. Met deze videos worden handige tips en tricks verteld zodat je je tijdens de les kan richten op de praktijk. De meeste mensen hebben tussen de 20 en 30 lessen nodig.</p> 
              </div>

              <div id="motortaining" class="double-feature__feature including-box">
                <div class="media--reverse">
                  <img src="@/assets/img/video-series-icon.png" alt="Learn UI Design Video Series">
                  <div>
                      <p class="double-feature__subtitle">ONDERDEEL II.</p>
                      <h3 class="double-feature__title">Praktijklessen</h3>
                  </div>
                </div>
                <p>De praktijklessen die je nodig hebt om het rijbewijs te halen beginnen altijd gestart vanaf het Haarlemmerplein. Marcel en de andere instructeurs zullen je de fijne kneepjes van het motorrijden bijbrengen.</p> 
              </div>

              <div id="theorie" class="double-feature__feature including-box">
                <div class="media--reverse">
                  <img src="@/assets/img/video-series-icon.png" alt="Learn UI Design Video Series">
                  <div>
                      <p class="double-feature__subtitle">ONDERDEEL III.</p>
                      <h3 class="double-feature__title">Theoriepakket</h3>
                  </div>
                </div>
                <p>De praktijklessen die je nodig hebt om het rijbewijs te halen beginnen altijd gestart vanaf het Haarlemmerplein. Marcel en de andere instructeurs zullen je de fijne kneepjes van het motorrijden bijbrengen.</p> 
              </div>
            </div>
          </div>
      </section>

      <div class="grid">
        <div><img src="@/assets/img/gallery/a.jpg" class="gallery-item" alt="Leerling geslaagd voor AVD"></div>
        <div><img src="@/assets/img/gallery/b.jpg" class="gallery-item" alt="Leerling rijdt in Amsterdam "></div>
        <div><img src="@/assets/img/gallery/c.jpg" class="gallery-item" alt="Leerling geslaagd voor AVB"></div>
        <div><img src="@/assets/img/gallery/d.jpg" class="gallery-item" alt="Leerling geslaagd voor auto examen"></div>
        <div><img src="@/assets/img/gallery/e.jpg" class="gallery-item" alt="Leerling rijdt door Amsterdam"></div>
        <div><img src="@/assets/img/gallery/f.jpg" class="gallery-item" alt="Leerling geslaagd voor de tussentijdse toets"></div>
        <div><img src="@/assets/img/gallery/g.jpg" class="gallery-item" alt="Leerling geslaagd voor de tussentijdse toets"></div>
        <div><img src="@/assets/img/gallery/h.jpg" class="gallery-item" alt="Leerling geslaagd voor de tussentijdse toets"></div>
      </div>


      <section id="syllabus" aria-label="Lesstof" class="section--dark diagonal--dark">
        <div class="section--container">

          <h2>Lesmateriaal</h2>
          <p>Elk niveau hebben wij onderverdeeld in lessen. Voor elke les hebben wij de aandachtspunten in een video voor jou uitgewerkt. Op die manier staat niks jou in de weg om het motorijden zo snel mogelijk op te pakken.</p>

          <div 
            v-for="item in opleiding"
            :key="item.title"
            class="unit" id="unit-1"
          >
            <h3 class="unit--title"><span>{{ item.number }}</span> {{ item.title }}</h3>
            <UnitLesson 
              v-for="lesson in item.lessons"
              :key="lesson.title"
              :title="lesson.title"
              :number="lesson.number"
            >
              {{ lesson.content }}
            </UnitLesson>
          </div>
        </div>

            <!-- <li class="unit--lesson">
              <a href="#" class="unit--lesson-title"><span>1.1</span> Begin Here</a>
              <ul class="unit--lesson-description">
                <li>How to use this course</li>
                <li>What makes for an ideal UI project for learning</li>
                <li>A few of my all-time favorite intro design resources</li>
              </ul>
            </li>
            <li class="unit--lesson">
                <a href="#" class="unit--lesson-title"><span>1.2</span> Introduction to Sketch</a>
                <ul class="unit--lesson-description">
                    <li>A primer on Sketch functionality</li>
                    <li>How to create and modify text, shapes, backgrounds, and more</li>
                </ul>
            </li>
            <li class="unit--lesson">
                <a href="#" class="unit--lesson-title"><span>1.3</span> Introduction to Figma</a>
                <ul class="unit--lesson-description">
                    <li>A primer on Sketch functionality</li>
                    <li>How to create and modify text, shapes, backgrounds, and more</li>
                </ul>
            </li>
            <li class="unit--lesson">
                <a href="#" class="unit--lesson-title"><span>1.4</span> Setting Up Your Workspace for UI Design</a>
                <ul class="unit--lesson-description">
                    <li>Other files you should keep on your computer for use in UI design</li>
                    <li>The most common hotkeys for speeding up UI design</li>
                    <li>Top tutorials for learning Sketch (or your UI app of choice)</li>
                </ul>
            </li>
            <li class="unit--lesson">
                <a href="#" class="unit--lesson-title"><span>1.5</span> How to Build Your Design Gut Instinct</a>
                <ul class="unit--lesson-description">
                    <li>A 2-step process for building your gut instinct</li>
                    <li>What to focus on so you can improve as quickly as possible</li>
                    <li>How to break down and analyze a "good" design</li>
                </ul>
            </li>
            <li class="unit--lesson">
                <a href="#" class="unit--lesson-title"><span>1.6</span> Starting a Project: Brand &amp; Goals</a>
                <ul class="unit--lesson-description">
                    <li>The 80-20 of branding – the most common brands designers should be able to create for</li>
                    <li>The most powerful questions for helping you determine yoru brand</li>
                    <li>How knowing your brand and goals can help make a good-but-plain design <em>great</em></li>
                </ul>
            </li>
            <li class="unit--lesson">
                <a href="#" class="unit--lesson-title"><span>1.7</span> 3 Ways to Design Above Your Level</a>
                <ul class="unit--lesson-description">
                    <li>How to move forward when there's a thousand things you could change</li>
                    <li>A strategy for how to iterate on designs efficiently</li>
                    <li>When to say "enough is enough" when you're designing</li>
                </ul>
            </li>
            <li class="unit--lesson">
                <a href="#" class="unit--lesson-title"><span>1.8</span> Finding &amp; Using Design Inspiration</a>
                <ul class="unit--lesson-description">
                    <li>The best places to find inspiration</li>
                    <li>My strategies for finding inspiration</li>
                    <li>Setting up systems to keep inspiration close at hand when you need it</li>
                    <li>How to use design inspiration without copying</li>
                </ul>
            </li>
          </ol>
        </div>
        <div class="unit" id="unit-2">
            <h3 class="unit--title"><span>II.</span> Fundamentals</h3>
            <ol class="unit--lessons">
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>2.1</span> Analyzing Aesthetics</a>
                    <ul class="unit--lesson-description">
                        <li>How the "Design Fundamentals" in this course differ from most courses or articles</li>
                        <li>What lessons from a well-designed room carry over into UI design</li>
                        <li>A case study of alignment, whitespace, and consistency</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>2.2</span> Alignment</a>
                    <ul class="unit--lesson-description">
                        <li>The importance of alignment</li>
                        <li>The trick to aligning tables and padded elements</li>
                        <li>Centering assymetric elements</li>
                        <li>Vertically aligning text</li>
                        <li>How an element's shape changes how you align it</li>
                        <li>The importance of alignment in an era of responsive design</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>2.3</span> Sizing</a>
                    <ul class="unit--lesson-description">
                        <li>The only 5 text sizes you'll ever need</li>
                        <li>Sizing elements on mobile vs. desktop</li>
                        <li>The 3 heuristics of proper sizing of UI elements</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>2.4</span> Spacing</a>
                    <ul class="unit--lesson-description">
                        <li>The 3 most important rules of spacing</li>
                        <li>How to add whitespace in data-heavy apps</li>
                        <li>Live examples of how to add whitespace to designs</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>2.5</span> Lighting &amp; Shadows</a>
                    <ul class="unit--lesson-description">
                        <li>How to use shadows to mimic the real world</li>
                        <li>How – and when –&nbsp;to color your shadows</li>
                        <li>The 2 major types of lights and what they mean for UI design</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>2.6</span> Grids</a>
                    <ul class="unit--lesson-description">
                        <li>When grids shine; when they fail</li>
                        <li>Thinking responsive when designing layouts</li>
                        <li>The major reason to break a grid</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>2.7</span> Consistency</a>
                    <ul class="unit--lesson-description">
                        <li>What consistency and inconsistency means to our brains</li>
                        <li>When to break consistency – and how to do it effectively</li>
                        <li>How to draw the line between consistency vs. standing out</li>
                    </ul>
                </li>
            </ol>
        </div>
        <div class="unit" id="unit-3">
            <h3 class="unit--title"><span>III.</span> Color</h3>
            <ol class="unit--lessons">
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>3.1</span> HSB</a>
                    <ul class="unit--lesson-description">
                        <li>An introduction to Hue, Saturation, and Brightness</li>
                        <li>Developing an intuitive understanding of the HSB system</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>3.2</span> Luminosity</a>
                    <ul class="unit--lesson-description">
                        <li>Why luminosity is such an important property in color</li>
                        <li>Practical situations in which luminosity can help you find the right color</li>
                        <li>Luminosity's role in accessibility</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>3.3</span> Gray: The Most Important Color</a>
                    <ul class="unit--lesson-description">
                        <li>Why gray is the most important - <em>and common</em> - color in UI design</li>
                        <li>How to make gray match any other color</li>
                        <li>Specific tactics for using gray on various supporting elements</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>3.4</span> Adjustment: The Most Important Color Skill</a>
                    <ul class="unit--lesson-description">
                        <li>How to generate entire interfaces from just 1 or 2 base colors</li>
                        <li>3 techniques for modifying colors in various circumstances</li>
                        <li>When to adjust different colors to <em>appear</em> the same</li>
                        <li>Why "color palettes" is a misnomer in day-to-day UI design</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>3.5</span> 3 Techniques to Fix Clashing Colors</a>
                    <ul class="unit--lesson-description">
                        <li>Major reasons colors appear to clash&nbsp;–&nbsp;or blend together –&nbsp;and how to fix them</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>3.6</span> Picking a Primary UI Color</a>
                    <ul class="unit--lesson-description">
                        <li>Why you shouldn't just go with blue for your app</li>
                        <li>Ideas for generating "non-obvious" themes (e.g. green for an environmental app)</li>
                        <li>Why app colors are less subjective than you think</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>3.7</span> Picking Secondary UI Colors</a>
                    <ul class="unit--lesson-description">
                        <li>The 2 major types of secondary UI colors</li>
                        <li>3 strategies for finding brand colors that match</li>
                        <li>Live demos of generating secondary UI colors</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>3.8</span> Dark Interfaces</a>
                    <ul class="unit--lesson-description">
                        <li>How to use lighting and shadows when the background is dark</li>
                        <li>The best times to use – and avoid –&nbsp;dark background UI</li>
                        <li>How to modify your color scheme for use with dark backgrounds</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>3.9</span> Gradients</a>
                    <ul class="unit--lesson-description">
                        <li>The 3 main types of gradients</li>
                        <li>The best way to make brilliant multi-stop gradients</li>
                        <li>Common mistakes with gradients and how to avoid them</li>
                    </ul>
                </li>
            </ol>
        </div>
        <div class="unit" id="unit-4">
            <h3 class="unit--title"><span>IV.</span> Typography</h3>
            <ol class="unit--lessons">
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>4.1</span> Typography Terminology: The Bare Minimum</a>
                    <ul class="unit--lesson-description">
                        <li>An illustrated guide to the most practical terms in using fonts on a day-to-day basis</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>4.2</span> Choosing Fonts</a>
                    <ul class="unit--lesson-description">
                        <li>What makes for good body vs. good header font</li>
                        <li>3 guidelines for picking fonts in any situation</li>
                        <li>An interactive list of high-quality fonts for various uses</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>4.3</span> Styling Text</a>
                    <ul class="unit--lesson-description">
                        <li>How to style your body text for legibility</li>
                        <li>Why styling paragraph text is different than other UI elements</li>
                        <li>How to get the most out of a single typeface (<em>because you don't always have a choice</em>)</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>4.4</span> Styling Text 2</a>
                    <ul class="unit--lesson-description">
                        <li>Special text stylings to keep in mind for editorial content –&nbsp;like blogs and articles</li>
                        <li>When to use condensed fonts</li>
                        <li>Tips for creating drop caps</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>4.5</span> Pairing Fonts</a>
                    <ul class="unit--lesson-description">
                        <li>A framework for making sense of the many font-pairing strategies</li>
                        <li>The 3 simplest heuristics for pairing fonts</li>
                        <li>How pairing fonts differs between clean/simple/neutral sites and heavily-branded sites</li>
                    </ul>
                </li>
            </ol>
        </div>
        <div class="unit" id="unit-5">
            <h3 class="unit--title"><span>V.</span> User Interface Components</h3>
            <ol class="unit--lessons">
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>5.1</span> Form Controls</a>
                    <ul class="unit--lesson-description">
                        <li>How to build out a "style guide" from the fewest controls</li>
                        <li>Do all your buttons look boring? 5+ variations to consider</li>
                        <li>The most important considerations when creating form controls</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>5.2</span> Icons 1: Vector Editing</a>
                    <ul class="unit--lesson-description">
                        <li>An overview of vector editing functionality, from shapes to scissors</li>
                        <li>Blend modes to give small illustrations a more realistic effect</li>
                        <li>A live example of illustration exercise incorporating the techniques of this lesson</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>5.3</span> Icons 2: Icon Design</a>
                    <ul class="unit--lesson-description">
                        <li>When to use – <em>and avoid</em> - icon libraries</li>
                        <li>A workflow for designing your own icons</li>
                        <li>Do you need to design icons on a grid?</li>
                        <li>Techniques to make icons match the visual style of your interface</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>5.4</span> Photography &amp; Imagery</a>
                    <ul class="unit--lesson-description">
                        <li>How to style photography to suit your brand</li>
                        <li>Places to find great photography&nbsp;– even&nbsp;for free</li>
                        <li>Photography in responsive designs</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>5.5</span> Lists &amp; Tables</a>
                    <ul class="unit--lesson-description">
                        <li>The most important things to remember when designing lists and tables</li>
                        <li>How lists and tables differ from other UI elements</li>
                        <li>Tables on mobile</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>5.6</span> Charts &amp; Data Visualizations</a>
                    <ul class="unit--lesson-description">
                        <li>The two most common mistakes in making gorgeous data visualizations</li>
                        <li>Why&nbsp;–&nbsp;and how – <em>every</em> data visualization should look great</li>
                        <li>The best workflow for creating a pie chart in Sketch</li>
                    </ul>
                </li>
            </ol>
        </div>
        <div class="unit" id="unit-6">
            <h3 class="unit--title"><span>VI.</span> Digital Platforms &amp; Paradigms</h3>
            <ol class="unit--lessons">
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>6.1</span> Responsive UI Design</a>
                    <ul class="unit--lesson-description">
                        <li>4 overarching principles to make responsive design easier</li>
                        <li>Plus 4 patterns to use to make any element responsive</li>
                        <li>Dozens of specific strategies for designing responsive UI</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>6.2</span> Designing Multi-State Screens</a>
                    <ul class="unit--lesson-description">
                        <li>The 8 states you need to consider designing for every page</li>
                        <li>When to use different controls for waiting/loading states</li>
                        <li>Includes a checklist cheatsheet to reference as you design</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>6.3</span> Accessibility</a>
                    <ul class="unit--lesson-description">
                        <li>The most common mistakes in creating accessible interfaces&nbsp;–&nbsp;and how to fix them</li>
                        <li>Why creating an accessible design is easier than you might initially think</li>
                        <li>My favorite plugins and tools for creating accessible designs</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>6.4</span> Overlaying Text on Images</a>
                    <ul class="unit--lesson-description">
                        <li>7 techniques for placing text on top of images in your UI</li>
                        <li>Pros and cons for each</li>
                        <li>Thinking about text on images in terms of accessbility and responsive design</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>6.5</span> Truncating Text</a>
                    <ul class="unit--lesson-description">
                        <li>9 methods for truncating text</li>
                        <li>Pros and cons for each</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>6.6</span> Mobile: iOS</a>
                    <ul class="unit--lesson-description">
                        <li>How to "think like Apple" when designing an interface</li>
                        <li>3 reasons to use the default iOS styles</li>
                        <li>The most common UI paradigm that differ between iPhone and other UIs</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>6.7</span> Mobile: Android/Material Design</a>
                    <ul class="unit--lesson-description">
                        <li>Key differences between Android and iOS design</li>
                        <li>The most common UI controls in Android apps</li>
                        <li>When to use – and not use –&nbsp;a floating action button</li>
                    </ul>
                </li>
            </ol>
        </div>
        <div class="unit" id="unit-7">
            <h3 class="unit--title"><span>VII.</span> Communicating Design</h3>
            <ol class="unit--lessons">
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>7.1</span> Click-Through Prototyping</a>
                    <ul class="unit--lesson-description">
                        <li>How to use your design app's prototyping functionality</li>
                        <li>Workarounds for one of the biggest drawbacks of click-through prototypes</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>7.2</span> Creating a Design Portfolio</a>
                    <ul class="unit--lesson-description">
                        <li>How to get portfolio projects when you don't have paying clients yet</li>
                        <li>Best practices and examples of great portfolios around the web</li>
                        <li>Tips for telling a good story with your project writeups</li>
                    </ul>
                </li>
                <li class="unit--lesson">
                    <a href="#" class="unit--lesson-title"><span>7.3</span> Finding Clients</a>
                    <ul class="unit--lesson-description">
                        <li>The best methods for a new freelancer to find clients</li>
                        <li>Best practices for turning 1 job into many</li>
                        <li>The <em>worst</em> places to find new clients</li>
                    </ul>
                </li>
                <li class="unit--lesson" id="presenting">
                    <a href="#" class="unit--lesson-title"><span>7.4</span> Presenting Your Designs</a>
                    <ul class="unit--lesson-description">
                        <li>The most common mistake when presenting a design</li>
                        <li>How to get the best possible feedback (and not have a design go <a href="http://theoatmeal.com/comics/design_hell" target="_blank">straight to hell</a>)</li>
                        <li>A sample presentation with example questions that I answer</li>
                    </ul>
                </li>
                <li class="unit--lesson" id="presenting">
                    <a href="#" class="unit--lesson-title"><span>7.5</span> Developer Handoff</a>
                    <ul class="unit--lesson-description">
                        <li>Why developer handoff is critically important</li>
                        <li>The most important things you can do for a developer when handing off a file</li>
                        <li>How a developer looks at your design file</li>
                    </ul>
                </li>
            </ol>
        </div> -->

        <div class="not-alone">
          <h2>Even voorstellen...</h2>
          <img width="150" src="@/assets/img/marcel.jpg" alt="Marcel Elmers">
          <p>
            Mijn naam is Marcel Elmers en ik heb Rijschool Veilig Verkeer (RVV) in 1995 opgericht. 
            Ik geloof dat iedere weggebruiker het verkeer écht kan begrijpen en zodra je dat doet, ook automatisch veilig kan rijden. 
            Met mijn rijschool sta ik voor een kwaliteit rijopleiding en probeer ik van leerlingen kundige bestuurders te maken. 
            En kundige bestuurders, die worden oud.</p>
          <ul>
              <li>Beschikt zelf over <strong>alle rijbewijzen</strong></li>
              <li>Onderhoudt al jaren een <strong>een slagingspercentage boven de 95%</strong> </li>
              <li>Persoonlijk en benaderbaar. <strong>Contact? Klik dan boven op Whatsapp</strong></li>
          </ul>
        </div>

      </section>

      <section id="references" aria-label="Referenties van anderen" class="diagonal--light">
        <div class="section--container">
          <h2>Ervaringen</h2>
          <Review
            v-for="review in reviews"
            :key="review.name"
            :name="review.name"
            :year="review.year"
            :image="review.image"
            :review="review.review"
          />
        </div>
      </section>


      <section id="faq" aria-label="Veelgestelde vragen" class="diagonal--medium">
        <div class="section--container">
          <h2>Veelgestelde vragen</h2>

          <ul>
            <li> 
              <strong>Heb je les?</strong> Kom dan altijd 15 minuten eerder zodat we meteen kunnen vertrekken als de les begint. 
            </li>
            <li>
              <strong>Waar begint de les?</strong>  De proefles begint op onze rijschool waar ook de simulator en alle motoren staan.
  Het adres is Haarlemmerplein 41 in Amsterdam. 
            </li>
            <li>
              <strong>Hoe lang duurt de rijopleiding?</strong>  Het aantal lessen dat je nodig hebt varieert sterk van persoon tot persoon, daarom is het moeilijk om die vraag te beantwoorden. Wat voor ons het meest belangrijk is, is dat elke leerling foutloos kan motorrijden. Bij ons ga je pas op examen zodra je er écht klaar voor bent.
            </li>
            <li>
              <strong>Wanneer kan ik lessen?</strong> Wij maken gebruik van e-rijschool, dat is een online programma waarmee je zelf je lessen kan boeken. Er is ook een app beschikbaar om dit vanaf mobiele apparaten te doen.
            </li>
          </ul>
        </div>
      </section>


      <section id="book_now" aria-label="Boek een proefles">
        <div class="section--container">
          <h2>Boek een proefles</h2>
          <RegistrationForm/>
        </div>
      </section>
  </main>
</template>

<script>
// @ is an alias to /src
import GoogleMap from "@/components/GoogleMap";
import Starburst from "@/components/Starburst";

import UnitLesson from "@/components/UnitLesson";
import Review from "@/components/Review";

import RegistrationForm from "@/components/Form/RegistrationForm";

export default {
  name: 'Home',
  components: {
    GoogleMap,
    Starburst,
    Review,
    UnitLesson,
    RegistrationForm
  },
  data () {
    return {
      reviews: [
        {
          name: "Judith Parkinson",
          image: require('@/assets/img/testimonials/judith.jpg'),
          year: "Leerling 2019",
          review: "Eigenwijs en eigengereid. Niet je rijbewijs halen, maar leren rijden. En als je dan kan rijden, dan haal je vanzelf je rijbewijs. TOP rijschool met TOP instructeurs. Bedankt Marcel, Menno, Nikki en Bob voor het ontdekken van de motorrijder in mij!"
        },
        {
          name: "Thom Johannesma",
          image: require('@/assets/img/testimonials/thom.jpg'),
          year: "Leerling 2019",
          review: "Super ervaring bij RVV, de instructerus zijn allemaal vriendelijk en erg bekwaam. Qua lestijden ook heel flexibel."
        },
        {
          name: "David",
          image: require('@/assets/img/testimonials/david.jpg'),
          year: "Leerling 2019",
          review: "Heel goede rijschool. Je kan er tijdens de lessen alle benodigde kleding lenen en communicatie gaat heel goed en duidelijk. Volgens mij niet de goedkoopste, maar zeker het geld waard. Ook is het vaak mogelijk last minute een les (of zelfs examen!) te boeken, ze zijn een stuk flexibeler dan je van een rijschool verwacht. Heb zonder mijn autorijbewijs goed leren motorrijden met dank aan de expertise van alle instructeurs van RVV."
        }
      ],
      opleiding: [
        {
          number: "I.",
          title: "Bediening & Gevarentraining",
          lessons: [
            { 
              number: "1.1",
              title: "Gevaren uit te weinig afstand houden",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "1.2",
              title: "Gevaren uit beperkt zicht op kruispunten",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "1.3",
              title: "Gevaren uit abc",
              content: "In deze les ga je x. y. z. doen"
            }
          ] 
        },
        {
          number: "II.",
          title: "Basis voertuigcontrole",
          lessons: [
            { 
              number: "2.1",
              title: "Bediening",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "2.2",
              title: "Wegrijden op lage snelheid",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "2.3",
              title: "Schakelen",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "2.3",
              title: "Naderen van een kruispunt",
              content: "In deze les ga je x. y. z. doen"
            }
          ] 
        },
        {
          number: "III.",
          title: "Speciale verrichtingen, traag",
          lessons: [
            { 
              number: "3.1",
              title: "Achteruit parkeren",
              content: "Het eerste cluster bestaat uit de oefening achteruit parkeren. In deze verplichte oefening loop je aan de rechterzijde van de rijbaan met de motor aan de hand. Daarna parkeer je de motor achteruit in een denkbeeldig parkeervak en zet je de motor op de standaard. Vervolgens haal je de motor weer van de standaard en loop je naar rechts het parkeervak uit."
            },
            { 
              number: "3.2",
              title: "Langzame slalom",
              content: "De verplichte oefening in het tweede cluster is de langzame slalom. Er geldt geen richtlijn voor de snelheid. Gezien de geringe tussenafstand ligt een stapvoets tempo voor de hand. Het gebruik van een slippende koppeling is bij deze oefening verplicht. Van belang is verder de combinatie van juiste bediening, langzaam rijden en het behouden van de balans. Dit alles doe je natuurlijk zonder de pylonen aan te raken! "
            },
            { 
              number: "3.3",
              title: "Wegrijden uit parkeervak",
              content: "Bij deze oefening rijd je vanuit stilstand uit een parkeervak weg. Je maakt een haakse bocht en rijdt enkele meters rechtuit. De rijbaanbreedte is drie meter. Het belangrijkste van deze oefening is dat je gecontroleerd een scherpe bocht weet te maken, direct na het wegrijden. Je examinator geeft aan in welke richting je moet wegrijden."
            },
            { 
              number: "3.4",
              title: "Denkbeeldige acht",
              content: "Met deze oefening laat je zien dat je een complete (denkbeeldige) acht kunt rijden in een rechthoekig kader. Je rijdt met trekkende motor en houdt daarbij een gelijkmatige snelheid aan. Je mag je voetrem gebruiken en eventueel een slippende koppeling."
            },
            { 
              number: "3.5",
              title: "Stapvoets rechtdoor rijden",
              content: "Hier is het de bedoeling dat je naast de lopende examinator blijft rijden over een afstand van twintig meter. Er wordt gelet op snelheid, balans en een juiste bediening van de motor. Je maakt gebruik van een slippende koppeling. Je voetrem mag je bij deze keuzeoefening ook gebruiken, maar je houdt je voeten tijdens het rijden op de voetsteunen. Voor het aangegeven richtpunt kom je tot stilstand, je mag daarbij de voorrem gebruiken."
            },
            { 
              number: "3.6",
              title: "Halve draai (links- of rechtsom)",
              content: "Als de examinator voor deze oefening kiest dan rijd je met licht trekkende motor op een denkbeeldige rijbaan. Na de tweede pylon maak je in één vloeiende beweging een halve draai naar links of rechts. Je rijdt dan terug naar het startpunt. Het gebruik van de voetrem en een slippende koppeling is toegestaan."
            }
          ] 
        },
        {
          number: "IV.",
          title: "Speciale verrichtingen, snel",
          lessons: [
            { 
              number: "4.1",
              title: "Uitwijkoefening",
              content: "Cluster drie bestaat uit drie oefeningen, waarvan de uitwijkoefening verplicht is. Bij de uitwijkoefening kom je met vijftig kilometer per uur aanrijden door de poort. Vijftien meter na de poort moet je vóór een denkbeeldig muurtje van pylonen naar links uitwijken. Daarna keer je weer terug naar de eigen weghelft."
            },
            { 
              number: "4.2",
              title: "Snelle slalom",
              content: "Bij de snelle slalom zijn zes pylonen opgesteld. Deze slalom neem je bij een snelheid van minstens dertig kilometer per uur met trekkende motor. Belangrijk is dat het vloeiend en gelijkmatig gebeurt en dat je vanuit de heupen stuurt."
            },
            { 
              number: "4.3",
              title: "Vertragingsoefening",
              content: "Bij deze oefening trek je vanuit stilstand op om snel te komen tot een snelheid van vijftig kilometer per uur. Je rijdt dan in tenminste de derde versnelling. Na het tweede poortje rem je af tot 30 kilometer per uur en schakel je minimaal één versnelling terug. Daarna rijd je met deze snelheid een slalom om drie pylonen die acht meter uit elkaar staan."
            },
            { 
              number: "4.4",
              title: "Noodstop",
              content: "In het vierde cluster is de noodstop de verplichte oefening. Je rijdt minimaal vijftig kilometer per uur. Na het poortje draai je het gas dicht, ontkoppel je direct en rem je maximaal om zo snel mogelijk tot stilstand te komen. Natuurlijk verlies je de controle over de motor niet."
            },
            { 
              number: "4.5",
              title: "Precisiestop",
              content: "Bij de precisiestop gaat het erom dat je vlak voor het tweede poortje stilstaat. Je rijdt eerst vijftig kilometer per uur en remt beheerst als je het eerste poortje van twee pylonen passeert. Daarna moet je de motor zeventien meter verderop tot stilstand brengen. Belangrijk is dat je gelijkmatig remt gedurende de zeventien meter, zonder dat grote correcties in remkracht nodig zijn. Vlak voor je stilstaat, schakel je terug naar de eerste versnelling."
            },
            { 
              number: "4.6",
              title: "Stopproef",
              content: "Naast de precisiestop kan de examinator ook nog kiezen voor de stopproef als keuzeoefening. Het doel van deze oefening is dat je technisch goed en fors remt met een korte remweg. Je rijdt eerst vijftig kilometer per uur en draait bij het poortje het gas dicht, remt direct met beide remmen en ontkoppelt. Je schakelt kort voordat je stilstaat terug naar de eerste versnelling."
            }
          ] 
        },
        {
          number: "V",
          title: "Wegplaatsing, bochten & sturen",
          lessons: [
            { 
              number: "5.1",
              title: "Dit is een eerste les",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "5.2",
              title: "Dit is een tweede les",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "5.3",
              title: "Dit is een derde les",
              content: "In deze les ga je x. y. z. doen"
            }
          ] 
        },
        {
          number: "VI",
          title: "Kruispunten",
          lessons: [
            { 
              number: "6.1",
              title: "Snelheid en wegplaatsing",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "6.2",
              title: "Rotondes",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "6.3",
              title: "Gelijkwaardige kruispunten",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "6.4",
              title: "Ongelijkwaardige kruispunten",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "6.5",
              title: "Speciale kruispunten",
              content: "In deze les ga je x. y. z. doen"
            }
          ] 
        },
        {
          number: "VII",
          title: "Snelwegen",
          lessons: [
            { 
              number: "7.1",
              title: "Invoegen",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "7.2",
              title: "Wegplaatsing & afstand houden",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "7.3",
              title: "Inhalen",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "7.4",
              title: "Uitvoegen",
              content: "In deze les ga je x. y. z. doen"
            },
          ] 
        },
        {
          number: "VIII",
          title: "Examenroutes",
          lessons: [
            { 
              number: "8.1",
              title: "Dit is een eerste les",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "8.2",
              title: "Dit is een tweede les",
              content: "In deze les ga je x. y. z. doen"
            },
            { 
              number: "8.3",
              title: "Dit is een derde les",
              content: "In deze les ga je x. y. z. doen"
            }
          ] 
        },
      ]
    }
  }
}
</script>
